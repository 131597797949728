<template>
  <div class="write">
    <div class="title">
      <span>编辑药品</span>
    </div>
    <div class="content">
      <div class="close" @click="closePopup">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="item">
          <span class="span">药品名称</span>
          <el-input placeholder="请输入药品名称" v-model="info.ypmc"></el-input>
        </div>
        <div class="item">
          <span class="span">市场价</span>
          <el-input placeholder="请输入市场价" v-model="info.scj"></el-input>
        </div>
        <div class="item">
          <span class="span">售价</span>
          <el-input placeholder="请输入售价" v-model="info.sj"></el-input>
        </div>
        <div class="item">
          <span class="span">药品分类</span>
          <el-select v-model="info.ypfl" placeholder="设置权限标识">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">药品分类</span>
          <el-select v-model="info.ypfl2" placeholder="设置药品单位">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">药品图片</span>
          <el-upload class="avatar-uploader"
            action=""
            :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <img v-if="info.yptp" :src="info.yptp" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <span class="upload">点击上传</span>
          </el-upload>
        </div>
        <div class="item item1">
          <span class="span">功能主治</span>
          <el-input placeholder="这里是一段主治功能的描述" class="input" v-model="info.gnzz"></el-input>
        </div>
        <div class="item item1">
          <span class="span">不良反应</span>
          <el-input placeholder="这里是一段主治功能的描述" class="input" v-model="info.blfy"></el-input>
        </div>
        <div class="item item1">
          <span class="span">使用注意事项</span>
          <el-input placeholder="这里是一段主治功能的描述" class="input" v-model="info.syzysx"></el-input>
        </div>
        <div class="item">
          <span class="span">进药厂商</span>
          <el-input placeholder="请输入" v-model="info.jycs"></el-input>
        </div>
        <div class="item">
          <span class="span">进药厂商</span>
          <el-input placeholder="批准文号" v-model="info.jycs2"></el-input>
        </div>
        <div class="item">
          <span class="span">启用状态</span>
          <el-radio-group v-model="info.qyzt">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </div>
        <div class="item item1">
          <span class="span">详情描述</span>
          <el-input placeholder="这里是一段主治功能的描述" class="input" v-model="info.xxms"></el-input>
        </div>
      </div>
      <div class="btns">
        <el-button @click="closePopup">取消</el-button>
        <el-button class="button" @click="submit">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      options: [],
      value: '',
      radio: '1',
      imageUrl: '',
      info: {
        id: 0,
        ypmc: '',
        scj: '',
        sj: '',
        ypfl: '',
        ypfl2: '',
        yptp: '',
        gnzz: '',
        blfy: '',
        syzysx: '',
        jycs: '',
        jycs2: '',
        qyzt: '',
        xxms: '',
        ssjgbh: '',
        ssjgmc: '',
        createtime: '',
        updatetime: '',
        ypglid: '',
        tjjgmc: '',
        tjjgbm: '',
        tjjgcmhid: '',
        tjjgcmhmc: ''
      }
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    // https://app.apifox.com/project/2726972/apis/api-81591470
    async getInfo () {
      try {
        const { data: res } = await this.$http.post(`/zhyyapi/yyglypglcha/${this.id}`)
        console.log('药品管理根据id获取', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.info = res.data
      } catch (error) {
        console.log('药品管理根据id获取请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    handleAvatarSuccess (res, file) {
      this.info.yptp = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    async submit () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-148452874
      try {
        const res = await this.$http.put('/ykdyyglypgls', this.info)
        console.log('药品管理修改', res)
        if (res.status !== 200) return this.$message.error(res.msg || res.message)
        this.$message.success('修改成功')
      } catch (error) {
        console.log('药品管理修改请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 3.75rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 3.3375rem;
    height: .6625rem;
    padding-top: .0625rem;
    box-sizing: border-box;
    line-height: .6625rem;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: .25rem;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    height: 700px;
    margin-top: 10px;
    padding: 50px 65px;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 32px;
      right: 24px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }

    .content-title {
      margin-top: 20px;
      color: #01EEFD;
      font-size: 18px;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
    }

    .item {
      display: flex;
      align-items: center;
      width: 33%;
      margin-top: 20px;
      color: #01EEFD;
      font-size: 16px;

      .avatar-uploader {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 170px;
        height: 73px;
        border: 1px solid #FFFFFF;
        color: #fff;

        .upload {
          margin-left: 10px;
        }
      }

      .span {
        margin-right: 10px;
        white-space: nowrap;
      }

      .span1 {
        width: 108px;
      }

      .el-input,
      .el-select {
        // width: 150px;
        height: 38px;
      }

      .input {
        width: 525px;
      }
    }

    .item1 {
      width: 100%;
    }

    .btns {
      position: absolute;
      bottom: 70px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 240px;
      height: 46px;

      .el-button {
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        background-color: #33465C;
        border-radius: 0;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }

  /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
  /deep/.el-radio__input.is-checked .el-radio__inner {
    background-color: #01EEFD;
    border-color: #01EEFD;
  }

  .el-checkbox,
  .el-radio {
    color: #fff;
  }

  /deep/.el-checkbox__input.is-checked+.el-checkbox__label,
  /deep/.el-radio__input.is-checked+.el-radio__label {
    color: #01EEFD;
  }
}
</style>
