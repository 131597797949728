<template>
  <div class="knowledgeBase">
    <div class="tab">
      <span :class="{ title: tabIndex === index }" v-for="(item, index) in tabs" :key="index" @click="tabClick(index)">{{ item }}</span>
    </div>
    <div class="content">
      <Tab1 v-if="tabIndex === 0" @subOpenInfo="openInfo"></Tab1>
      <Tab2 v-else-if="tabIndex === 1" @subOpenInfo="openInfo"></Tab2>
    </div>
    <Info v-if="showInfo" @close="closeInfo"></Info>
  </div>
</template>

<script>
import Tab1 from './tab1.vue'
import Tab2 from './tab2.vue'
import Info from './info.vue'
export default {
  components: {
    Tab1,
    Tab2,
    Info
  },
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '2020031',
        type: '1',
        value: false
      }, {
        date: '2020-02-27 17:20',
        name: '2020031',
        type: '1',
        value: true
      }],
      options: [{
        value: '',
        label: ''
      }],
      tabs: Object.freeze(['药品订单', '问诊订单']),
      value: '',
      tabIndex: 0,
      showInfo: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    openInfo () {
      this.showInfo = true
    },
    closeInfo () {
      this.showInfo = false
    }
  }
}
</script>

<style lang="less" scoped>
.knowledgeBase {
  position: relative;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  padding: 20px;
  border: 4px solid rgba(1, 124, 219, .27);
  box-sizing: border-box;
  background-color: #08223D;

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    span {
      height: 38px;
      line-height: 38px;
      margin-right: 16px;
      padding: 0 12px;
      text-align: center;
      color: #fff;
      font-size: 22px;
      background-color: #D5D5D5;
      cursor: pointer;
    }

    .title {
      background-color: #01EEFD;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 80px);
    margin-top: 20px;

    .intervene {
      position: relative;
      width: 100%;
      height: 100%;

      .info-title {
        width: 100%;
        height: 50px;
        margin-bottom: 20px;
        border-bottom: 1px solid #01EEFD;
        box-sizing: border-box;
        color: #01EEFD;
        font-size: 20px;
        font-weight: bold;
      }

      .info-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 10px;
        padding-bottom: 0;
        box-sizing: border-box;

        .left {
          display: flex;
          align-items: center;

          .search-input {
            width: 260px;
          }

          .el-select,
          .el-input {
            width: 200px;
            margin-right: 10px;
          }

          .date {
            margin-right: 10px;
          }

          .blue,
          .gray {
            margin-left: 40px;
            background-color: #04B4D0;
            color: #fff;
            border: none;
            border-radius: 0;
          }

          .gray {
            margin-right: 10px;
            background-color: #284867;
          }
        }

        .right {
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-radius: 50%;
          background-color: #04B4D0;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
        }
      }

    }
  }
}
</style>
