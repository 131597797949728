var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"write"},[_vm._m(0),_c('div',{staticClass:"content"},[_c('div',{staticClass:"close",on:{"click":_vm.closePopup}},[_c('span',{staticClass:"el-icon-close"})]),_c('div',{staticClass:"box"},[_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"table"},[_c('el-table',{attrs:{"data":_vm.tableData,"height":"200","header-cell-style":{ background: '#024276' }}},[_c('el-table-column',{attrs:{"label":"药品名称","prop":"name"}}),_c('el-table-column',{attrs:{"label":"药品图片"}}),_c('el-table-column',{attrs:{"label":"剂量"}}),_c('el-table-column',{attrs:{"label":"用法"}})],1)],1),_c('div',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"current-page":4,"background":"","page-sizes":[100, 200, 300, 400],"page-size":100,"layout":"total, prev, pager, next, jumper","total":400},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1),_vm._m(4),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("处方详情")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-title"},[_c('span',[_vm._v("基本信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('div',{staticClass:"item"},[_c('span',{staticClass:"span"},[_vm._v("诊断结果：")]),_c('span',{staticClass:"width"},[_vm._v("盗汗体虚严重，需要大补")])]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"span"},[_vm._v("开方医生：Mr.Ge")])]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"span"},[_vm._v("审核药师：小升")])]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"span"},[_vm._v("处方状态：已购买")])]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"span"},[_vm._v("患者姓名：小升")])]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"span"},[_vm._v("患者年龄：28")])]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"span"},[_vm._v("患者性别：男")])]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"span"},[_vm._v("备处方类型：中药")])]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"span"},[_vm._v("开方时间：2022-10-10 12:00:00")])]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"span"},[_vm._v("审核时间：2022-10-11 12:00:00")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-title"},[_c('span',[_vm._v("处方信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-title"},[_c('span',[_vm._v("复用说明")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('div',{staticClass:"item"},[_c('span',{staticClass:"span"},[_vm._v("使用频率：一日一次")])]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"span"},[_vm._v("用法：煎煮")])]),_c('div',{staticClass:"item item1"},[_c('span',{staticClass:"span"},[_vm._v("备注：")]),_c('span',{staticClass:"width"},[_vm._v("全部药物一起进行煎煮")])])])
}]

export { render, staticRenderFns }