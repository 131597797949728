<template>
  <div class="write">
    <div class="title">
      <span>删除</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <span>确认删除后，对应专家将被永久删除</span>
      </div>
      <div class="btns">
        <el-button>取消</el-button>
        <el-button class="button">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '选项1',
        label: '黄金糕'
      }],
      value: '',
      radio: '1'
    }
  },
  methods: {
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 3.75rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 3.3375rem;
    height: .6625rem;
    padding-top: .0625rem;
    box-sizing: border-box;
    line-height: .6625rem;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: .25rem;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    min-width: 800px;
    height: 5rem;
    margin-top: .125rem;
    padding: .625rem .8125rem;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: .175rem;
      right: .3rem;
      color: #fff;
      font-size: .3rem;
      cursor: pointer;
    }

    .content-title {
      margin-top: .25rem;
      color: #01EEFD;
      font-size: .225rem;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: calc(100% - .875rem);
      color: #01EEFD;
      font-size: .225rem;
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 3rem;
      height: .575rem;
      margin: 0 auto;

      .el-button {
        height: .375rem;
        line-height: .375rem;
        padding: 0 .125rem;
        text-align: center;
        color: #fff;
        font-size: .175rem;
        background-color: #33465C;
        border-radius: 0;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }
}
</style>
