<template>
  <div class="tab-box">
    <div class="intervene">
      <div class="info-title">
        <div class="info-box">
          <div class="left">
            <el-input class="el-input-width" placeholder="请输入订单号"></el-input>
            <el-input class="el-input-width" placeholder="请输入用户姓名"></el-input>
            <el-input class="el-input-width" placeholder="请输入用户手机号"></el-input>
            <el-select v-model="value" placeholder="请选择订单状态">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-button size="small" class="blue">搜索</el-button>
            <el-button size="small" class="gray">清空</el-button>
          </div>
        </div>
      </div>
      <div class="table">
        <el-table :data="tableData" height="340" :header-cell-style="{ background: '#024276' }">
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column prop="name" label="编号"></el-table-column>
          <el-table-column label="订单编号"></el-table-column>
          <el-table-column label="问诊用户"></el-table-column>
          <el-table-column label="就诊专家"></el-table-column>
          <el-table-column label="用户联系方式"></el-table-column>
          <el-table-column label="订单金额"></el-table-column>
          <el-table-column label="实付金额"></el-table-column>
          <el-table-column label="支付方式"></el-table-column>
          <el-table-column label="订单状态"></el-table-column>
          <el-table-column label="创建时间"></el-table-column>
          <el-table-column label="操作">
            <!-- slot-scope="scope" -->
            <template>
              <div class="flex flex1">
                <span @click="openInfo">订单详情</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '2020031',
        type: '1',
        value: false
      }, {
        date: '2020-02-27 17:20',
        name: '2020031',
        type: '1',
        value: true
      }],
      options: [{
        value: '',
        label: ''
      }],
      tabs: Object.freeze(['药品订单', '问诊订单']),
      value: ''
    }
  },
  methods: {
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    openInfo () {
      this.$emit('subOpenInfo')
    }
  }
}
</script>

<style lang="less" scoped>
.tab-box {
  width: 100%;
  height: 100%;

  .intervene {
      position: relative;
      width: 100%;
      height: 100%;

      .info-title {
        width: 100%;
        height: 50px;
        margin-bottom: 20px;
        border-bottom: 1px solid #01EEFD;
        box-sizing: border-box;
        color: #01EEFD;
        font-size: 20px;
        font-weight: bold;
      }

      .info-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 10px;
        padding-bottom: 0;
        box-sizing: border-box;

        .left {
          display: flex;
          align-items: center;

          .search-input {
            width: 260px;
          }

          .el-select,
          .el-input {
            width: 200px;
            margin-right: 10px;
          }

          .date {
            margin-right: 10px;
          }

          .blue,
          .gray {
            margin-left: 40px;
            background-color: #04B4D0;
            color: #fff;
            border: none;
            border-radius: 0;
          }

          .gray {
            margin-right: 10px;
            background-color: #284867;
          }
        }

        .right {
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-radius: 50%;
          background-color: #04B4D0;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
        }
      }

    }

  .table {
    overflow: hidden;
    width: 100%;
    margin-top: 10px;
    border: 4px #073F73 solid;
    box-sizing: border-box;
  }

  /deep/.el-table__header {
    width: 100% !important;
  }

  ::v-deep .el-table__body-wrapper {
    // &::-webkit-scrollbar {
    //   // 整个滚动条
    //   width: 0px; // 纵向滚动条的宽度
    // }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #01EEFD;
          font-size: 17px;
          font-weight: 400;
        }
      }
    }
  }

  /deep/.el-table__body tbody tr {
    color: #fff;
    font-size: 16px;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  .el-table--border,
  .el-table--group {
    border: 2px solid #ddd !important;
  }

  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: 1px solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: transparent;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: 1px solid #073F73 !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-table th.gutter {
    display: none;
    width: 0px;
  }

  /deep/.el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  /deep/ .el-checkbox__inner {
    background-color: transparent;
  }

  /deep/ .el-table__body-wrapper {
    background-color: #092B50;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;

    span {
      color: #0077F9;
      cursor: pointer;
      letter-spacing: 0;
    }

    .red {
      color: #FF0000;
    }

    //按钮文字显示
    .el-switch {
      width: 70px !important;
      height: 25px !important;
    }

    ::v-deep .el-switch__core {
      width: 100% !important;
      height: 24px;
      border-radius: 100px;
      border: none;
    }

    ::v-deep .el-switch__core::after {
      width: 20px;
      height: 20px;
      top: 2px;
    }

    ::v-deep .el-switch.is-checked .el-switch__core::after {
      margin-left: -21px;
    }

    /*关闭时文字位置设置*/
    ::v-deep .el-switch__label--right {
      position: absolute;
      z-index: 1;
      right: 6px;
      margin-left: 0px;
      color: rgba(255, 255, 255, 0.9019607843137255);

      span {
        font-size: 12px;
      }
    }

    /* 激活时另一个文字消失 */
    ::v-deep .el-switch__label.is-active {
      display: none;
    }

    /*开启时文字位置设置*/
    ::v-deep .el-switch__label--left {
      position: absolute;
      z-index: 1;
      left: 5px;
      margin-right: 0px;
      color: rgba(255, 255, 255, 0.9019607843137255);

      span {
        font-size: 12px;
      }
    }

  }

  .flex1 {
    justify-content: center;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }
}
</style>
