<template>
  <div class="write">
    <div class="title">
      <span>处方详情</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="content-title">
          <span>基本信息</span>
        </div>
        <div class="list">
          <div class="item">
            <span class="span">诊断结果：</span>
            <span class="width">盗汗体虚严重，需要大补</span>
          </div>
          <div class="item">
            <span class="span">开方医生：Mr.Ge</span>
          </div>
          <div class="item">
            <span class="span">审核药师：小升</span>
          </div>
          <div class="item">
            <span class="span">处方状态：已购买</span>
          </div>
          <div class="item">
            <span class="span">患者姓名：小升</span>
          </div>
          <div class="item">
            <span class="span">患者年龄：28</span>
          </div>
          <div class="item">
            <span class="span">患者性别：男</span>
          </div>
          <div class="item">
            <span class="span">备处方类型：中药</span>
          </div>
          <div class="item">
            <span class="span">开方时间：2022-10-10 12:00:00</span>
          </div>
          <div class="item">
            <span class="span">审核时间：2022-10-11 12:00:00</span>
          </div>
        </div>
        <div class="content-title">
          <span>处方信息</span>
        </div>
        <div class="table">
          <el-table :data="tableData" height="200" :header-cell-style="{ background: '#024276' }">
            <el-table-column label="药品名称" prop="name"></el-table-column>
            <el-table-column label="药品图片"></el-table-column>
            <el-table-column label="剂量"></el-table-column>
            <el-table-column label="用法"></el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
        <div class="content-title">
          <span>复用说明</span>
        </div>
        <div class="list">
          <div class="item">
            <span class="span">使用频率：一日一次</span>
          </div>
          <div class="item">
            <span class="span">用法：煎煮</span>
          </div>
          <div class="item item1">
            <span class="span">备注：</span>
            <span class="width">全部药物一起进行煎煮</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '选项1',
        label: '黄金糕'
      }],
      value: '',
      radio: '1',
      tableData: [{
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: false
      }, {
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: true
      }]
    }
  },
  methods: {
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 200px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    height: 700px;
    margin-top: 10px;
    padding: 40px 65px;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 30px;
      right: 24px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }

    .content-title {
      height: 46px;
      line-height: 46px;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 18px;
    }

    .box {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 40px);

      &::-webkit-scrollbar {
        width: 0;
      }

      .list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 20px;
      }
    }

    .white {
      width: 80%;
      margin-top: 20px;
      padding: 19px;
      background-color: #fff;
      color: #333333;
      font-size: 16px;
    }

    .item {
      display: flex;
      align-items: center;
      margin-top: 20px;
      margin-right: 40px;
      color: #01EEFD;
      font-size: 16px;

      .span {
        margin-right: 10px;
        white-space: nowrap;
      }

      .width {
        width: 525px;
        height: 38px;
        line-height: 38px;
        padding: 0 20px;
        box-sizing: border-box;
        color: #333333;
        font-size: 16px;
        background-color: #fff;
      }
    }

    .item1 {
      width: 100%;
    }

    .table {
        width: 99%;
        margin-top: .125rem;
        border: .05rem #073F73 solid;
      }

      ::v-deep .el-table__body-wrapper {
        &::-webkit-scrollbar {
          // 整个滚动条
          width: 0rem; // 纵向滚动条的宽度
        }
      }

      ::v-deep .el-table th.gutter {
        display: none;
        width: 0;
      }

      ::v-deep .el-table colgroup col[name='gutter'] {
        display: none;
        width: 0;
      }

      ::v-deep .el-table__body {
        width: 100% !important;
      }

      /deep/.el-table__header-wrapper {
        thead {
          th {
            div {
              color: #01EEFD;
              font-size: .2125rem;
              font-weight: 400;
            }
          }
        }
      }

      /deep/.el-table__body tbody tr {
        color: #fff;
        font-size: .2rem;
        background-color: #092B50;
      }

      /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
        background-color: #092B50;
      }

      /deep/.el-table tbody tr:hover>td {
        background-color: transparent !important
      }

      .el-table--border,
      .el-table--group {
        border: .025rem solid #ddd !important;
      }

      .el-table--border td,
      .el-table--border th,
      .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
        border-right: .0125rem solid #ddd !important;
      }

      /deep/.el-table td {
        border-bottom: .0125rem solid #01EEFD;
      }

      /deep/.el-table thead tr th {
        border-color: transparent;
      }

      /deep/ .el-table--border::after,
      /deep/ .el-table--group::after,
      /deep/ .el-table::before {
        background-color: #073F73;
      }

      /deep/.el-table--border {
        border: .0125rem solid #073F73 !important;
      }

      /deep/.el-table .cell {
        text-align: center;
        color: #01EEFD;
      }

      /deep/.el-table th.gutter {
        display: none;
        width: 0rem;
      }

      /deep/.el-table colgroup col[name='gutter'] {
        display: none;
        width: 0;
      }

      /deep/ .el-checkbox__inner {
        background-color: transparent;
      }

      /deep/ .el-table__body-wrapper {
        background-color: #092B50;
      }

      .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: .125rem;

        ::v-deep.el-pagination__total,
        ::v-deep.el-pagination__jump {
          color: #01EEFD;

          .el-input__inner {
            color: #fff;
            background-color: transparent;
          }
        }

        ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
          background-color: #01EEFD;
        }
      }
  }
}
</style>
