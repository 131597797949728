<template>
  <div class="upload">
    <div class="box">
      <div class="title">
        <div class="text">
          <span>上传文件</span>
        </div>
      </div>
      <div class="input">
        <input type="file" id="file" name="file" multiple @change="handleFileUpload" style="display: none;">
        <label for="file" class="button">选择文件</label>
      </div>
      <div class="content">
        <div class="list" v-for="(item, index) in percentages" :key="index">
          <span>{{ item.name }}</span>
          <div class="progress">
            <el-progress v-if="item.percentage !== 0" :text-inside="true" :stroke-width="20" :percentage="item.percentage"></el-progress>
          </div>
          <el-button type="primary" size="small" @click="upload(index)">上传</el-button>
        </div>
      </div>
      <div class="button">
        <el-button @click="clsoe">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      files: [],
      cancelToken: null,
      percentages: []
    }
  },
  methods: {
    clsoe () {
      this.$emit('close')
    },
    handleFileUpload (e) {
      console.log(e)
      this.files = Array.from(e.target.files)
      for (let index = 0; index < this.files.length; index++) {
        const obj = { name: this.files[index].name, percentage: 0 }
        this.percentages.push(obj)
      }
    },
    upload (index) {
      const file = this.files[index]
      const cancelToken = this.$http.CancelToken
      const source = cancelToken.source()

      const formData = new FormData()
      formData.append('file', file)

      this.cancelToken = source

      this.$http.post('/excels/putypml', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          // 处理上传进度
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          console.log('上传进度:' + percentCompleted + '%')
          this.percentages[index].percentage = percentCompleted
        },
        cancelToken: source.token
      })
        .then(response => {
          console.log('上传成功', response)
          if (response.data.code !== 0) {
            this.percentages[index].percentage = 0
            return this.$message.error(response.data.msg)
          }
          this.$message.success('上传成功')
        })
        .catch(error => {
          console.log(error)
          this.percentages[index].percentage = 0
          if (this.$http.isCancel(error)) {
            this.$message.error('上传已取消')
          } else {
            this.$message.error('上传失败')
          }
        })
    },
    cancelUpload () {
      if (this.cancelToken) {
        this.cancelToken.cancel('取消上传操作') // 取消上传
      }
    }
  }
}
</script>

<style lang="less" scoped>
.upload {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);

  .box {
    overflow: hidden;
    width: 40%;
    height: 60%;
    border-radius: 10px;
    background-color: #fff;

    .title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #409EFF;
      color: #fff;
      font-size: 20px;
    }

    .input {
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      padding-left: 20px;
      box-sizing: border-box;

      input[type="file"] {
        color: transparent;
      }

      .button {
        width: 100px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 10px;
        background-color: #409EFF;
        color: #fff;
        font-size: 14px;
      }
    }

    .content {
      overflow-y: scroll;
      width: 100%;
      padding: 0 20px;
      height: calc(100% - 160px);
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 0;
      }

      .list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        margin: 10px 0;
        padding: 0 20px;
        border: 1px solid #d6d6d6;
        border-radius: 10px;
        box-sizing: border-box;
        color: #000;
        font-size: 15px;

        .text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100px;
        }

        .progress {
          width: 50%;
        }
      }
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px;
    }
  }
}
</style>
